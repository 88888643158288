import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import AboutMe from './components/about/AboutMe';
import Work from './components/work/Work';
import Projects from './components/projects/Projects';
import ResumeRedirect from './components/ResumeRedirect'; 
import Contact from './components/contact/Contact';
import './GlobalStyles.css';

function App() {
  const [isDarkMode, setDarkMode] = useState(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
  );

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const handleSystemThemeChange = (e) => {
      setDarkMode(e.matches);
    };

    const matcher = window.matchMedia('(prefers-color-scheme: dark)');
    matcher.addListener(handleSystemThemeChange);

    if (isDarkMode) {
      document.body.setAttribute('data-theme', 'dark');
    } else {
      document.body.setAttribute('data-theme', 'light');
    }

    return () => {
      matcher.removeListener(handleSystemThemeChange);
    };
  }, [location, isDarkMode, navigate]);

  return (
    <div className={isDarkMode ? 'dark-mode' : 'light-mode'}>
      <Header isDarkMode={isDarkMode} setDarkMode={setDarkMode} />
      <Routes>
        <Route path="/" element={<><AboutMe isDarkMode={isDarkMode} /></>} />
        <Route path="/work" element={<><Work isDarkMode={isDarkMode} /></>} />
        <Route path="/projects" element={<><Projects isDarkMode={isDarkMode} /></>} />
        <Route path="/contact" element={<Contact isDarkMode={isDarkMode} />} />
        <Route path="/resume" element={<ResumeRedirect />} />
      </Routes>
      <Footer isDarkMode={isDarkMode} setDarkMode={setDarkMode} />
    </div>
  );
}

export default App;
