import React from 'react';
import './Footer.css';
import { FaHeart } from "react-icons/fa";

const Footer = ({ isDarkMode }) => {
  return (
    <div>
      <div className="gradient-separator"></div>
      <footer className="footer">
        <div className="footer-content">
          Made with
          <FaHeart className="heart" aria-label="love" />
          by Rohan Gosavi
        </div>
      </footer>
    </div>
  );
};

export default Footer;
