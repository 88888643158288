import React from 'react';
import './AboutMe.css';

const AboutMe = ({ isDarkMode }) => {
  return (
    <>
      <div className="home">
        <div className="home-intro">
          <h1>नमस्ते!</h1>
          <h2>
            I&apos;m <span className="name-outline" data-text="Rohan">Rohan</span>,
          </h2>
          <p>Passionate Full Stack Developer</p>
        </div>
      </div>
    </>
  );
};

export default AboutMe;
