import React, { useState } from "react";
import "./Contact.css"; 

const Contact = () => {
  const [formData, setFormData] = useState({ email: "", message: "" });
  const [status, setStatus] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const scriptURL = "https://script.google.com/macros/s/AKfycbx4310Er5a0-3DGpFG_weT7fY9PrV3Guii7cKfIvirN8lqa_jXkKi7WZXGdSHfYs5Jf/exec";

    try {
      const response = await fetch(scriptURL, {
        method: "POST",
        body: new URLSearchParams({
          email: formData.email,
          message: formData.message,
        }),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (response.ok) {
        setStatus("Got your message. Will review soon..!");
        setIsSubmitted(true);
        setFormData({ email: "", message: "" });
      } else {
        setStatus("Failed to send the message. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      setStatus(`An error occurred: ${error.message}`);
    } finally {
      setIsSubmitting(false);

      setTimeout(() => {
        setIsSubmitted(false);
        setStatus("");
      }, 4000); 
    }
  };

  return (
    <>
      <div className="home">

        <div className="home-intro">
          <h1>Shaping the</h1>
          <h2>
            <span className="name-outline" data-text="Rohan">future</span>
          </h2>
          <h1>starts by connecting</h1>
          <h2>
            <span className="name-outline" data-text="Rohan">today!</span>
          </h2>
        </div>

        {/* <div className="description">
          <p className="contact-description">
            Whether you’re excited to collaborate, share ideas, or simply have a chat, I’m all ears.
            Feel free to send me a message—I’ll respond within 24 hours!
          </p>
        </div> */}

        <div className="contact-container">
        {isSubmitting ? (
            <div className="loading-container">
              <div className="loading-spinner"></div>
            </div>
          ) : isSubmitted ? (
            <div className="success-container">
              <p className="success-message">{status}</p>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="contact-form">
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                  className="form-input"
                  required
                />
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Type your message here."
                  className="form-textarea"
                  rows="4"
                  style={{ resize: "none" }}
                  required
                />
              </div>
              <button type="submit" className="submit-button">
                Send
              </button>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default Contact;
