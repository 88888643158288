import React from "react";
import { NavLink } from "react-router-dom"; 
import "./Header.css";
import { DarkModeSwitch } from "react-toggle-dark-mode";

const Header = ({ isDarkMode, setDarkMode }) => {
  const toggleDarkMode = () => {
    setDarkMode(!isDarkMode);
  };

  return (
    <header data-theme={isDarkMode ? "dark" : "light"}>
      <div className="navbar-wrapper">
        <div className="navbar-container">
            <NavLink to="/" className="link">
              Home
            </NavLink>
            <NavLink to="/work" className="link">
              Work
            </NavLink>
            <NavLink to="/projects" className="link">
              Projects
            </NavLink>
            <NavLink to="/contact" className="link">
              Contact
            </NavLink>
            <div className="link">
                <DarkModeSwitch checked={isDarkMode} onChange={toggleDarkMode} size={20} />
            </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
